import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userName: '',
    headImg: '',
    phone: '',
    token: '',
    inviteCode: '',
    bindId: '',
    h5_openid: '',
  },
  mutations: {
    updateUserInfo(state, data) {
      state.userName = data.userName || state.userName;
      state.headImg = data.headImg || state.headImg;
      state.phone = data.phone || state.phone;
      state.token = data.token || state.token;
      localStorage.setItem('token', state.token);
      localStorage.setItem('headImg', state.headImg);
      localStorage.setItem('userName', state.userName);
      localStorage.setItem('phone', state.phone);
    },
    initUserInfo(state) {
      // 兼容之前官网字段
      const beforeUserName = localStorage.getItem('username');
      const beforeHeadImg = localStorage.getItem('headimg_url');
      let storageUserName = localStorage.getItem('userName');
      let storageHeadImg = localStorage.getItem('headImg');

      if (beforeUserName) {
        storageUserName = beforeUserName;
        localStorage.removeItem('username');
        localStorage.setItem('userName', storageUserName);
      }
      if (beforeHeadImg) {
        storageHeadImg = beforeHeadImg;
        localStorage.removeItem('headimg_url');
        localStorage.setItem('headImg', storageHeadImg);
      }
      localStorage.removeItem('h5_openId');
      const storagePhone = localStorage.getItem('phone');
      const storageToken = localStorage.getItem('token');
      state.userName = storageUserName || state.userName;
      state.headImg = storageHeadImg || state.headImg;
      state.phone = storagePhone || state.phone;
      state.token = storageToken || state.token;
    },
    resetUserInfo(state) {
      localStorage.clear();
      state.userName = '';
      state.headImg = '';
      state.phone = '';
      state.token = '';
    },
    setInviteCode(state, data) {
      state.inviteCode = data;
    },
    setBindId(state, data) {
      state.bindId = data;
    },
    setH5OpenId(state, data) {
      state.h5_openid = data;
    },
  },
});
