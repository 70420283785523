import Vue from 'vue';
import SVGA from 'svgaplayerweb';
// import MetaInfo from 'vue-meta-info';
import VueLazyload from 'vue-lazyload';
import App from './App.vue';
import router from './router';
import store from './store';
import './icons/index';
import './plugins/element';
import './assets/styles/animate.css';
import './assets/styles/public.css';
import './plugins/flexible';

Vue.config.productionTip = false;
Vue.prototype.$svgaParser = new SVGA.Parser();

// Vue.use(MetaInfo);
Vue.use(VueLazyload);
// Vue.use(VueLazyLoad,{
// error:'', //加载失败的图

// loading:'' //加载中的默认图

// })

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app');
