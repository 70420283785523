/* eslint-disable */
// timeout定时任务封装
export const timeoutTask = (task, time) => {
  const tmpTimeout = setTimeout(() => {
    task();
    clearTimeout(tmpTimeout);
  }, time);
};
// 判断dom是否在视口内
export const isElementNotInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= (window.innerHeight || document.documentElement.clientHeight)
    || rect.bottom <= 0
  );
};

// 判断当前打开环境是PC还是手机端
export const isPc = () => {
  let tmpIsPc = true;
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    tmpIsPc = false;
  }
  return tmpIsPc;
};

// 获取链接中的参数
export const getQueryString = (name) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
  if (window.location.href.indexOf('?') === -1) { return ''; }
  const result = window.location.href.split('?')[1].match(reg);
  return result ? decodeURIComponent(result[2]) : null;
};

// 判断是否在Iframe中打开
export const isIframeEnv = () => {
  let tmpFlag = false;
  if (self != top) {
    tmpFlag = true;
  }
  return tmpFlag;
};

// 获取随机值  min<= num < max
export const getRandom = (min, max) => Math.floor(Math.random() * (max - min)) + min;