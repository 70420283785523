<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { getQueryString } from '@/utils/index';

export default {
  name: 'app',
  data() {
    return {
      // isPc: true,
    };
  },
  created() {
    const tmpInviteCode = getQueryString('inviteCode');
    const bindId = getQueryString('bindId');
    const h5OpenId = getQueryString('h5_openId');
    if (tmpInviteCode) {
      this.$store.commit('setInviteCode', tmpInviteCode);
    }
    if (bindId) {
      this.$store.commit('setBindId', bindId);
      console.log(bindId);
    }
    if (h5OpenId) {
      this.$store.commit('setH5OpenId', h5OpenId);
      console.log(h5OpenId);
    }
    // 如果自定义后台链接访问过
    // 则清除自定义后台链接访问的缓存配置
    const funcs = localStorage.getItem('funcs');
    if (funcs) {
      localStorage.removeItem('funcs');
      localStorage.removeItem('token');
    }

    // this.isPc = isPc();
    // if (!this.isPc) {
    //   console.log(location.href);
    //   if (location.href.indexOf('phoneRegister') > -1) {
    //     this.$router.replace({
    //       path: '/phoneRegister',
    //     });
    //   } else if (location.href.indexOf('home') > -1) {
    //     this.$router.replace({
    //       path: '/phoneHome',
    //     });
    //   } else if (location.href.indexOf('pcHome') > -1) {
    //     this.$router.replace({
    //       path: '/',
    //     });
    //   } else {
    //     this.$router.replace({
    //       path: '/phoneAnnualMeeting',
    //     });
    //   }
    // } else {
    //   this.$router.replace({
    //     path: '/',
    //   });
    // }
    /* eslint-disable */
    // const _53code = document.createElement("script");
    // _53code.src = "https://tb.53kf.com/code/code/2c891055109c51301f8b2e9df6910cdf1/1";
    // const s = document.getElementsByTagName("script")[0];
    // s.parentNode.insertBefore(_53code, s);
  },
  mounted() {
    // window.testShowKefu = this.showKefuScript;
    // setTimeout(() => {
    //   /* eslint-disable */
    //   const _53code = document.createElement("script");
    //   _53code.src = "https://tb.53kf.com/code/code/2c891055109c51301f8b2e9df6910cdf1/1";
    //   const s = document.getElementsByTagName("script")[0];
    //   s.parentNode.insertBefore(_53code, s);
    // }, 10000);
  },
  methods: {},
  watch: {
    // $route({ meta }) {
    //   // this.$nextTick(() => document.title = meta.title || '嗨喵悦动 - 嗨喵悦动大屏互动');
    //   setTimeout(() => document.title = meta.title || '嗨喵悦动 - 嗨喵悦动大屏互动')
    // }
  }
};
</script>

<style lang="less">
/* 全局样式 */
* {
  margin: 0;
  padding: 0;
}
html {
  // font-size: 75px;
}
body {
  // background-color: #f4f4f4;
  background-color: rgba(255, 255, 255, 1);
  color: #000000;
  // font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
}
div {
  box-sizing: border-box;
}
img {
  max-width: 100%;

  height: auto;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
// #mobile_icon_div {
//   display: none !important;
// }


@media screen and (max-width: 1725px) {
  :root {
    --mentor-padding: 99.9975px !important;
  }
}
@media screen and (max-width: 1500px) {
  
  #header {
    // padding: 0 !important;
    // justify-content: center;
    // .right {
    //   margin-left: 16.755px;
    // }
     
  }


  :root {
    --mentor-padding: 52.5px !important;
  }
}

@media screen and (max-width: 1400.25px) {
  .adativeItemscontainer {
    padding: 0 90px !important;
  }
  .mentor-container {
    .ec-bottom {
      .item {
        flex-basis: 585px !important;
      }
      .left {
        flex-basis: 217.5px !important;
      }
      .tags {
        span {
          padding: 6px 12px !important;
        }
      }
    }
  }
  .app-web-site {
    .aw-header {
      padding: 0 99.9975px !important;
    }
    .aw-hh-container {
    padding: 0 99.9975px !important;
  }
  }
  :root {
    --newhome-padding: 99.9975px !important;
  }
  #header {
    padding: 0 99.9975px !important;
    .game-container {
      .content {
        padding-left: 99.9975px !important;
        padding-right: 99.9975px !important;
      }
    }
    // .middle {
    //   margin: 0 auto !important;
    // }
    // .right {
    //   margin: 0 auto !important;
    // }
    // .left {
    //   margin: 0 0 0 auto !important;
    // }
    .free-container {
      .flex-content {
        padding-left: 233.4140625px;
        padding-right: 0;
        justify-content: center;
        >div {
          margin: 0 auto 0 0 !important;
        }
      }
      
    }
  }
  .classicalContainer {
    justify-content: space-between !important;
    .classicalItem {
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 1332.75px) {
 .ec-bottom {
  .list {
    .item {
    padding: 15px !important;
    flex-basis: 536.25px !important;
    
  }
  }

  .left {
    flex-basis: 202.5px !important;
  }
 } 
}

@media screen and (max-width: 1300.5px) {
  #header {
    .middle>.el-menu {
      >.el-menu-item{
      padding: 0 15px !important;
      }
      >.el-submenu {
        .el-submenu__title {
          padding: 0 15px !important;
        }
      }
    }
    .free-container {
      .flex-content {
        padding-left: 99.9975px;
        padding-right: 99.9975px;
        justify-content: center;
        >div {
          margin: 0 auto 0 0 !important;
        }
      }
      
    }

    
  }
  .customercorp {
    // margin: 0 !important;
    .item {
      // margin: 0 auto !important;
    }
  }

  .fundationItemscontainer {
    .items {
      .itemcontainer {
        margin: 0 0 37.5px 0 !important;
        // margin-left: auto !important;
        // margin-right: auto !important;
      }
    }
  }
  
}
</style>
