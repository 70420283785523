import Vue from 'vue';
import {
  Carousel,
  CarouselItem,
  Menu,
  Submenu,
  MenuItem,
  Input,
  Message,
  Col,
  Row,
  Pagination,
} from 'element-ui';

Vue.use(Pagination);
Vue.use(Col);
Vue.use(Row);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Input);
Vue.component(Message);
Vue.prototype.$message = Message;
